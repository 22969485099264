import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import StartPageProductGrid from './Content/StartPageProductGrid';
import Row from './Content/Row';
import NewsletterRow from './Content/NewsletterRow';
import ProductRow from './Content/ProductRow';
import startPageQuery from './StartPageQuery.gql';

import { theme } from '../Theme';

const startPageComponents = {
  ROW: Row,
  PRODUCTROW: ProductRow,
  NEWSLETTERROW: NewsletterRow
};

const StartPageCustomContent = styled('div')`
  width: 100%;
  margin-bottom: 10px;
`;

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StartPage = ({ startPageId }) => {
  return (
    <Fragment>
      <StartPageWrapper>
        <Query
          variables={startPageId && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {result => {
            const { loading, data } = result;
            if (loading) return <LoadingPage />;
            if (!data) return null;

            if (
              data.startPage &&
              data.startPage.items &&
              data.startPage.items.length > 0
            ) {
              return (
                <>
                  {data.startPage.content && (
                    <StartPageCustomContent
                      dangerouslySetInnerHTML={{
                        __html: data.startPage.content
                      }}
                    />
                  )}
                  <StartPageRenderer
                    result={result}
                    startPageComponents={startPageComponents}
                    ProductGrid={StartPageProductGrid}
                    LoadingPage={LoadingPage}
                  />
                </>
              );
            } else return null;
          }}
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
