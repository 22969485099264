import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Box } from './Row';
import NewsletterField from '../../Newsletter/NewsletterField';

const RowWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${props => (props.fullWidth ? '100%' : '90rem')};
  margin: auto auto 60px auto;

  ${theme.below.lg} {
    display: block;
    flex: none;
    margin-bottom: 0;

    .box.image-hidden {
      text-decoration: none;
      height: auto;
      .content {
        position: relative;
        width: 100%;
        padding: 30px;
      }
    }
    .box {
      margin-left: auto!important;
      margin-right: auto!important;
    }
  }

  .slick-arrow {
    display: none!important;
  }

  ${props => (props.fullWidth ? 'padding: 0;' : 'padding: 20px;')}
  ${props => (props.padding ? 'padding: 10px; .box {margin: 10px;}' : '')}

  ${props =>
    props.fullWidth && props.boxeslength === 2
      ? '.box { &:nth-child(1) {margin-bottom: 20px; margin-right: 10px;} &:nth-child(2) {margin-bottom: 20px; margin-left: 10px;}'
      : ''}
`;

const NewsletterBox = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: ${props => props.width};
  align-items: ${props => props.vposition};
  justify-content: ${props => props.hposition};
  text-align: ${props => props.textalign};
  background: ${props => props.backgroundcolor};
  color: ${props => props.color};
  text-decoration: none;
  padding: 0 10px;
  flex-direction: column;
  ${theme.below.lg} {
    padding: 30px 10px;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 2rem;
  }
  p {
    max-width: 550px;
    margin: 0 auto 1.5rem auto;
  }

  input {
    background: ${props => props.backgroundcolor};
    color: ${props => props.color};
    padding-left: 10px;
    border-color: black;
  }
  svg {
    color: black;
  }
`;
const NewsletterInputs = styled('div')``;

const Row = ({ padding, fullWidth, boxes }) => {
  return (
    <RowWrapper
      padding={padding ? true : false}
      fullWidth={fullWidth ? true : false}
      boxeslength={boxes.length}
    >
      {boxes.map((box, index) => (
        <React.Fragment key={index}>
          {box.newsletter ? (
            <NewsletterBox
              width={box.width ? box.width : 1}
              backgroundcolor={
                box.backgroundColor ? box.backgroundColor : 'transparent'
              }
              color={box.color ? box.color : 'black'}
              vposition={box.vposition ? box.vposition : 'center'}
              hposition={box.hposition ? box.hposition : 'center'}
              textalign={box.textAlign ? box.textAlign : 'center'}
            >
              <h2>{box.title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: box.text
                }}
              />
              <NewsletterInputs>
                <NewsletterField />
              </NewsletterInputs>
            </NewsletterBox>
          ) : (
            <Box key={index} box={box} />
          )}
        </React.Fragment>
      ))}
    </RowWrapper>
  );
};

export default Row;
