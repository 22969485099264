import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const RowWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${props => (props.fullWidth ? '100%' : '90rem')};
  margin: auto auto 60px auto;

  ${theme.below.lg} {
    display: block;
    flex: none;
    margin-bottom: 0;

    .box.image-hidden {
      text-decoration: none;
      height: auto;
      .content {
        position: relative;
        width: 100%;
        padding: 30px;
      }
    }
    .box {
      margin-left: auto!important;
      margin-right: auto!important;
    }
  }

  .slick-arrow {
    display: none!important;
  }

  
  ${props => (props.fullWidth ? 'padding: 0;' : 'padding: 20px;')}
  ${props => (props.padding ? 'padding: 10px; .box {margin: 10px;}' : '')}

  ${props =>
    props.fullWidth && props.boxeslength === 2
      ? '.box { &:nth-child(1) {margin-bottom: 20px; margin-right: 10px;} &:nth-child(2) {margin-bottom: 20px; margin-left: 10px;}'
      : ''}
`;
const BoxWrapperLink = styled('a')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: ${props => props.width};
  align-items: ${props => props.vposition};
  justify-content: ${props => props.hposition};
  text-align: ${props => props.textalign};
  background: ${props => props.backgroundcolor};
  color: ${props => props.color};
  text-decoration: none;
  padding: 0 10px;

  ${theme.below.lg} {
    /* justify-content: center;
    text-align: center;
    align-items: center; */
    padding: 5px 10px;

    &.box.image-visible {
      * {
        //text-shadow: 0px 0px 25px #2d2d2d;
      }
    }
  }

  &.no-link {
    cursor: default;
  }

  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    &.image-link {
      img {
        transform: scale(1.05);
      }
    }
  }
  a {
    text-decoration: none;
    color: ${props => (props.color ? props.color : 'white')};
  }
  h2,
  h3,
  h4,
  p {
    color: ${props => (props.color ? props.color : 'white')};
  }
`;
const BoxWrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: ${props => props.width};
  align-items: ${props => props.vposition};
  justify-content: ${props => props.hposition};
  text-align: ${props => props.textalign};
  background: ${props => props.backgroundcolor};
  color: ${props => props.color};
  text-decoration: none;
  padding: 0 10px;

  ${theme.below.lg} {
    /* justify-content: center;
    text-align: center;
    align-items: center; */
    padding: 5px 10px;

    &.box.image-visible {
      * {
        //text-shadow: 0px 0px 25px #2d2d2d;
      }
    }
  }

  &.no-link {
    cursor: default;
  }

  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    &.image-link {
      img {
        transform: scale(1.05);
      }
    }
  }
  a {
    text-decoration: none;
    color: ${props => (props.color ? props.color : 'white')};
  }
  h2,
  h3,
  h4,
  p {
    color: ${props => (props.color ? props.color : 'white')};
  }
`;
const Content = styled('div')`
  position: ${props => (props.textbelow ? 'relative' : 'absolute')};
  padding: ${props => (props.textbelow ? '20px' : '20px')};
  max-width: 1200px;
  margin: auto;
  z-index: 1;
  color: ${props => (props.textbelow ? 'black;' : 'black')};
  text-shadow: 0px 0px 10px
    ${props => (props.color?.indexOf('fff') > -1 ? 'black' : 'white')};

  ${theme.below.lg} {
    padding: ${props => (props.textbelow ? '20px 20px 0 20px' : '20px')};
  }

  h2 {
    font-size: ${props => (props.textbelow ? '1rem' : '2.5rem')};

    line-height: 1.2;
    ${theme.below.lg} {
      font-size: ${props => (props.textbelow ? '1rem' : '1.8rem')};
    }
  }
  h3 {
    font-size: 20px;
    letter-spacing: 0.5px;

    font-weight: 700;
    ${theme.below.lg} {
      font-size: 16px;
      &.large-not-active {
        font-size: 20px;
      }
    }
  }
  p {
    max-width: 650px;
    font-size: ${props => (props.textbelow ? '14px' : '1rem')};
    margin: ${props =>
      props.textbelow ? '.5rem auto' : '1.5rem auto 0.5rem auto'};
    ${theme.below.lg} {
      font-size: ${props => (props.textbelow ? '14px' : '12px')};
    }
  }
`;
const Price = styled('div')`
  font-size: 1rem;
  font-weight: bold;
  span.old {
    text-decoration: line-through;
    color: white;
  }
  span.new {
    background: ${theme.colors.red};
    color: white;
    padding: 3px;
    margin-left: 10px;
  }
`;

const ButtonsRow = styled('div')`
  &.multiple {
    margin: 10px -5px 0 -5px;
    > span {
      margin: 20px 5px 0 5px;
    }
  }
`;

const FakeButton = styled('span')`
  display: inline-block;
  padding: 10px 40px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 1rem;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};
  border-radius: 30px;

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }
`;

const LinkButton = styled(Link)`
  display: inline-block;
  padding: 10px 40px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 1rem;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};
  border-radius: 30px;

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }
`;

const Dots = styled('ul')`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin: 0;
`;
const Dot = styled('button')`
  background: #c4c4c4;
  border: 0;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin: 0 5px;
  &:focus {
    outline: none;
  }

  .slick-active & {
    background: ${theme.colors.primary};
  }
`;

export const Box = ({ box }) => {
  const { selectedChannel } = React.useContext(ChannelContext);

  if (box.hposition === 'left') {
    box.hposition = 'flex-start';
    box.textAlign = 'left';
  }
  if (box.hposition === 'center') {
    box.hposition = 'center';
    box.textAlign = 'center';
  }
  if (box.hposition === 'right') {
    box.hposition = 'flex-end';
    box.textAlign = 'right';
  }

  if (box.vposition === 'top') {
    box.vposition = 'flex-start';
  }
  if (box.vposition === 'center' || box.vposition === 'middle') {
    box.vposition = 'center';
  }
  if (box.vposition === 'bottom') {
    box.vposition = 'flex-end';
  }

  if (selectedChannel && !box?.image.match(/http/gi)) {
    box.image = selectedChannel?.url + box.image;
  }

  return (
    <>
      {box.link?.indexOf('http') > -1 ? (
        <BoxWrapperLink
          href={box?.link ? box.link : '#'}
          target="_blank"
          onClick={!box.link ? e => e.preventDefault() : null}
          className={
            (box.image !== undefined
              ? 'box image-visible '
              : 'box image-hidden ') +
            (!box.link ? ' no-link ' : ' image-link ')
          }
          width={box.width ? box.width : 1}
          backgroundcolor={
            box.backgroundColor ? box.backgroundColor : 'transparent'
          }
          color={box.color ? box.color : 'black'}
          vposition={box.vposition ? box.vposition : 'center'}
          hposition={box.hposition ? box.hposition : 'center'}
          textalign={box.textAlign ? box.textAlign : 'center'}
          textbelow={box.textBelow ? 'true' : ''}
        >
          <Content
            className="content"
            color={box.color ? box.color : 'black'}
            textbelow={box.textBelow ? 'true' : ''}
          >
            {box.priceBefore && box.priceNow && (
              <Price>
                <span className="old">{box.priceBefore}</span>
                <span className="new">{box.priceNow}</span>
              </Price>
            )}
            {box.smallTitle && (
              <h3
                className={box.title ? 'large-active' : 'large-not-active'}
                dangerouslySetInnerHTML={{
                  __html: box.smallTitle
                }}
              />
            )}
            {box.title && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: box.title
                }}
              />
            )}
            {box.text && (
              <p
                dangerouslySetInnerHTML={{
                  __html: box.text
                }}
              />
            )}
            {box.buttons && (
              <ButtonsRow className={box.buttons.length > 1 ? 'multiple' : ''}>
                {box.buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {!box.link && button.link ? (
                      <LinkButton
                        to={button.link}
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </LinkButton>
                    ) : (
                      <FakeButton
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </FakeButton>
                    )}
                  </React.Fragment>
                ))}
              </ButtonsRow>
            )}
          </Content>

          {box.image && box.image.length > 0 && (
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <Image
                    className="row-image"
                    aspect={box.aspect ? box.aspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                ) : (
                  <Image
                    className="row-image"
                    aspect={box.mobileAspect ? box.mobileAspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                )
              }
            </Above>
          )}
        </BoxWrapperLink>
      ) : (
        <BoxWrapper
          to={box?.link ? box.link : '#'}
          target={box.link?.indexOf('http') > -1 ? '_blank' : '_self'}
          onClick={!box.link ? e => e.preventDefault() : null}
          className={
            (box.image !== undefined
              ? 'box image-visible '
              : 'box image-hidden ') +
            (!box.link ? ' no-link ' : ' image-link ')
          }
          width={box.width ? box.width : 1}
          backgroundcolor={
            box.backgroundColor ? box.backgroundColor : 'transparent'
          }
          color={box.color ? box.color : 'black'}
          vposition={box.vposition ? box.vposition : 'center'}
          hposition={box.hposition ? box.hposition : 'center'}
          textalign={box.textAlign ? box.textAlign : 'center'}
          textbelow={box.textBelow ? 'true' : ''}
        >
          <Content
            className="content"
            color={box.color ? box.color : 'black'}
            textbelow={box.textBelow ? 'true' : ''}
          >
            {box.priceBefore && box.priceNow && (
              <Price>
                <span className="old">{box.priceBefore}</span>
                <span className="new">{box.priceNow}</span>
              </Price>
            )}
            {box.smallTitle && (
              <h3
                className={box.title ? 'large-active' : 'large-not-active'}
                dangerouslySetInnerHTML={{
                  __html: box.smallTitle
                }}
              />
            )}
            {box.title && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: box.title
                }}
              />
            )}
            {box.text && (
              <p
                dangerouslySetInnerHTML={{
                  __html: box.text
                }}
              />
            )}
            {box.buttons && (
              <ButtonsRow className={box.buttons.length > 1 ? 'multiple' : ''}>
                {box.buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {!box.link && button.link ? (
                      <LinkButton
                        to={button.link}
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </LinkButton>
                    ) : (
                      <FakeButton
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </FakeButton>
                    )}
                  </React.Fragment>
                ))}
              </ButtonsRow>
            )}
          </Content>

          {box.image && box.image.length > 0 && (
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <Image
                    className="row-image"
                    aspect={box.aspect ? box.aspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                ) : (
                  <Image
                    className="row-image"
                    aspect={box.mobileAspect ? box.mobileAspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                )
              }
            </Above>
          )}
        </BoxWrapper>
      )}
    </>
  );
};

const Row = ({
  padding,
  fullWidth,
  mobileSlider,
  hideInMobile,
  hideInDesktop,
  boxes
}) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />
  };
  return (
    <Above breakpoint="lg">
      {matches =>
        matches ? (
          <>
            {!hideInDesktop && (
              <RowWrapper
                padding={padding ? true : false}
                fullWidth={fullWidth ? true : false}
                boxeslength={boxes.length}
              >
                {boxes.map((box, index) => (
                  <React.Fragment key={index}>
                    {!box.hideInDesktop && <Box key={index} box={box} />}
                  </React.Fragment>
                ))}
              </RowWrapper>
            )}
          </>
        ) : (
          <>
            {mobileSlider && !hideInMobile ? (
              <RowWrapper
                padding={padding ? true : false}
                fullWidth={fullWidth ? true : false}
                boxeslength={boxes.length}
              >
                <Slider {...settings}>
                  {boxes.map((box, index) => (
                    <React.Fragment key={index}>
                      {!box.hideInMobile && <Box key={index} box={box} />}
                    </React.Fragment>
                  ))}
                </Slider>
              </RowWrapper>
            ) : (
              <>
                {!hideInMobile && (
                  <RowWrapper
                    padding={padding ? true : false}
                    fullWidth={fullWidth ? true : false}
                    boxeslength={boxes.length}
                  >
                    {boxes.map((box, index) => (
                      <React.Fragment key={index}>
                        {!box.hideInMobile && <Box key={index} box={box} />}
                      </React.Fragment>
                    ))}
                  </RowWrapper>
                )}
              </>
            )}
          </>
        )
      }
    </Above>
  );
};

export default Row;
