import React from 'react';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import { theme } from '../../Theme';
import startPageProductsQuery from './StartPageProductsQuery.gql';
import ProductGrid from '../../CategoryPage/ProductGrid';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 90rem;
  padding: 20px;
  margin: auto;
  .product-card {
    width: 20%;
    flex: 1;
  }

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  ${theme.below.lg} {
    padding-bottom: 0;
    > ul {
      .product-card {
        min-width: 55vw;
        flex: none;
        a {
          margin-bottom: 0;
        }
      }

      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const Products = ({ products, title }) => {
  const { data, error, loading } = useQuery(startPageProductsQuery, {
    variables: { articleNumbers: products },
    errorPolicy: 'all'
  });

  if (loading || error) return null;
  if (!data || !data.products.length) return null;

  return (
    <Wrapper>
      {title ? <h2>{title}</h2> : null}
      <ProductGrid
        products={data.products}
        listName="Startpage products"
        loading={loading}
        imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
      />
    </Wrapper>
  );
};

export default Products;
